'use client';

import { ENV, ROUTES } from '#/data/constants';
import { sendWebHook } from '#/lib/webhook';
import { useSession } from 'next-auth/react';
import { useEffect } from 'react';

export default function ErrorClientComponent({ error }: { error: Error }) {
  const { data: session, status } = useSession();

  useEffect(() => {
    const sendErrorNotification = async (error: Error) => {
      const subject = 'Error in Application';
      const comment = `${error.message}\n\n${error.stack}`;
      const webhookUrl = ENV.NEXT_PUBLIC_WEBHOOK_GOOGLE_CHAT;
      const publicApiUrl = ENV.NEXT_PUBLIC_API_URL;
      const id = `userId: ${session?.user?.id}`;
      const name = `userName: ${session?.user?.name}`;
      const provider = `userProvider: ${session?.user?.provider}`;

      if (webhookUrl) {
        try {
          await sendWebHook(
            webhookUrl,
            `[Error Notification] ${subject} \n\n${comment} \n\n${id} \n\n${name} \n\n${provider} \n\n${publicApiUrl}`
          );
        } catch (err) {
          console.error('Failed to send error notification:', err);
        }
      }
    };

    if (status !== 'loading') {
      sendErrorNotification(error).then(
        () => (window.location.href = ROUTES.HOME)
      );
    }
  }, [session, status]);

  return null;
}
