'use client';

import { NextAuthProvider } from '#/provider/auth-provider';
import ErrorClientComponent from './error-client';

export default function GlobalError({
  error,
  reset
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  return (
    <html>
      <body>
        <NextAuthProvider>
          <ErrorClientComponent error={error} />
        </NextAuthProvider>
      </body>
    </html>
  );
}
