export async function sendWebHook(url: string, message: string) {
  try {
    const data = JSON.stringify({
      text: message
    });

    let resp;
    await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=UTF-8'
      },
      body: data
    }).then((response: any) => {
      resp = response;
    });
    return resp;
  } catch (err) {
    console.log(err);
  }
}
